import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './autorizaciones.css';

const Autorizaciones = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container autorizaciones-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='autorizaciones_title'>
                    <h1>Oficios de Autorización</h1>
                </div>
            </div>
        </div>

        <div className='row'>

          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2024">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2024" aria-expanded="false" aria-controls="collapseOne2024">2024</button>
              </h2>
              <div id="collapseOne2024" class="accordion-collapse collapse" aria-labelledby="headingOne2024" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-group">
                    <li class="list-group-item">SH_0917_ 2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH_0917_ 2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-2755-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-2755-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3125-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3125-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-2602-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-2602-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-2244-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-2244-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3127-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3127-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-2758-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-2758-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-2780-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-2780-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3118-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3118-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0983-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0983-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0989-2-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0989-2-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0989-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0989-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0989-3-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0989-3-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0989-5<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0989-5.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-1094-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-1094-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0989-4-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0989-4-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-1913-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-1913-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0929-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0929-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH_CPF_0929_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH_CPF_0929_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH_CPF_1084_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH_CPF_1084_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH_CPF_1085_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH_CPF_1085_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-0986-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-0986-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-1407-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-1407-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-0224-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-0224-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-1808-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-1808-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-1410-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-1410-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-1809-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-1809-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-1810-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-1810-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3890-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3890-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3884-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3884-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3888-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3888-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-3733-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-3733-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3426-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3426-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3435-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3435-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4459-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4459-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3436-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3436-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4384-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4384-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4385-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4385-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4450-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4450-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-3882-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-3882-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4380-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4380-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4381-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4381-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4383-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4383-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4386-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4386-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4429-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4429-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4457-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4457-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">HACIENDA-A-PRISR-GI-2024-2706-00330<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/HACIENDA-A-PRISR-GI-2024-2706-00330.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">HACIENDA-DGP-FGPAR-GI-2024-2710-00748<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/HACIENDA-DGP-FGPAR-GI-2024-2710-00748.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">HACIENDA-DGP-FOCOE-GI-2024-2710-00778<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/HACIENDA-DGP-FOCOE-GI-2024-2710-00778.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">HACIENDA-DGP-FGPAR-GI-2024-2706-0077<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/HACIENDA-DGP-FGPAR-GI-2024-2706-0077.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4590-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Autorizaciones/SH-CPF-4590-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2023-octubre">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2023-octubre" aria-expanded="false" aria-controls="collapseOne2023-octubre">2023 | Octubre - Diciembre</button>
              </h2>
              <div id="collapseOne2023-octubre" class="accordion-collapse collapse" aria-labelledby="headingOne2023-octubre" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-group">
                    <li class="list-group-item">SH-4847-20-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Autorizaciones/SH-4847-20-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4824-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Autorizaciones/SH-CPF-4824-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4826-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Autorizaciones/SH-CPF-4826-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4120-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Autorizaciones/SH-CPF-4120-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SH-CPF-4803-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Autorizaciones/SH-CPF-4803-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

        </div>
    </div>
  )
}

export default Autorizaciones