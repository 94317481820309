import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './contratos.css';

const Contratos = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container contratos-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='contratos_title'>
                    <h1>Contratos</h1>
                </div>
            </div>
        </div>

        <div className='row'>

          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2024">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2024" aria-expanded="false" aria-controls="collapseOne2024">2024</button>
              </h2>
              <div id="collapseOne2024" class="accordion-collapse collapse" aria-labelledby="headingOne2024" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-group">
                    <li class="list-group-item">11069780_1107001_1106918_11070565_11070701_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/11069780_1107001_1106918_11070565_11070701_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_003_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_003_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_004_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_004_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_005_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_005_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_006_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_006_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_007_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_007_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_008_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_008_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_009_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_009_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_010_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_010_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                  
                    <li class="list-group-item">SC_DA_CONTRATO_011_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_011_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_012_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_012_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_013_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_013_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_014_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_014_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_015_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_015_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_019_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_019_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_020_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_020_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">Contrato_007_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Contrato_007_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a>
                      <ul><li class="list-group-item">Factura_A574F4AA_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Factura_A574F4AA_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li></ul>
                    </li>
                    <li class="list-group-item">Contrato_015_2024_0002<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Contrato_015_2024_0002.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a>
                      <ul><li class="list-group-item">Factura_81967143_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Factura_81967143_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li></ul>
                    </li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-025-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-025-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-029-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-029-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-030-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-030-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-031-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-031-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_001_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_001_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_022_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_022_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-023-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DA-CONTRATO-023-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-024-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DA-CONTRATO-024-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-025-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-025-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DAF-CONTRATO-027-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DAF-CONTRATO-027-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-028-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-028-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-030-1-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-030-1-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-032-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-032-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-033-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-033-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-034-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DA-CONTRATO-034-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-035-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-035-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">015_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/015_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">007_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/007_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACING_9BAE3186-CD32-4F16-A411-24254CEE4CFC_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACING_9BAE3186-CD32-4F16-A411-24254CEE4CFC_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-ENNIO-GETSEMANI-SERRANO-TORRES<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-ENNIO-GETSEMANI-SERRANO-TORRES.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-SUSANA-LOPEZ-FERNANDEZ<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-SUSANA-LOPEZ-FERNANDEZ.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-JESUS-AGUILAR-MARQUEZ<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-JESUS-AGUILAR-MARQUEZ.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-BROXEL-AM-59557<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-BROXEL-AM-59557.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-ELVIRA-CHAVEZ-CRUZ<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-ELVIRA-CHAVEZ-CRUZ.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-ELIAS-MANZANO-BALLESTEROS<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-ELIAS-MANZANO-BALLESTEROS.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-BROXEL-AM-58708<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-BROXEL-AM-58708.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-BROXEL-AM-59401<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-BROXEL-AM-59401.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-BROXEL-AM-58707<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-BROXEL-AM-58707.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-SUJEY-CHAVEZ<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-SUJEY-CHAVEZ.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-ROBERTO-LEO<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-ROBERTO-LEO.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-NADIA-LOPEZ<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-NADIA-LOPEZ.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-MARIA-FERNANDA<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-MARIA-FERNANDA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-KAREN-YEZAILA<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-KAREN-YEZAILA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-JUAN-CARLOS-ORTEGA<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-JUAN-CARLOS-ORTEGA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-EVENCIO-ALARCON<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-EVENCIO-ALARCON.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-ELIAS-MANZANO<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-ELIAS-MANZANO.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-ANA-ZHARIFE<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-ANA-ZHARIFE.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FAC-NANCY-ANTONIETA-JIMENEZ-DOROTEO<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FAC-NANCY-ANTONIETA-JIMENEZ-DOROTEO.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FAC-ENROQUE-MERCADO-OMAÑA<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FAC-ENROQUE-MERCADO-OMAÑA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FACTURA-BROXEL-60417<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACTURA BROXEL 60417.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FACTURA-BROXEL-60418<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACTURA BROXEL 60418.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FAC-MIRNA-YANIRA-GARCIA-VARGAS<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FAC-MIRNA-YANIRA-GARCIA-VARGAS.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FAC-J-CONCEPCION-JAEN-GASPAR<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FAC-J-CONCEPCION-JAEN-GASPAR.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FAC-ANERICA-ALEJANDRA-FEMAT-VIVEROS<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FAC-ANERICA-ALEJANDRA-FEMAT-VIVEROS.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FAC-ANA-LUISA-PRADO-HERRERA<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FAC-ANA-LUISA-PRADO-HERRERA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">FAC-ELIAS-MANZANO-BALLESTEROS<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FAC-ELIAS-MANZANO-BALLESTEROS.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                    
                    <li class="list-group-item">SC-CA-CONTRATO-042-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-042-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-044-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-044-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-041-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-041-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-039-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-039-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-046-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-046-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-040-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-040-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-057-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-057-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACTURA-ADY-ADRIANA-MOLINA-MEDINA-2<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACTURA-ADY-ADRIANA-MOLINA-MEDINA-2.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACTURA-ADY-ADRIANA-MOLINA-MEDINA<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACTURA-ADY-ADRIANA-MOLINA-MEDINA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACTURA-SIMON-HERNANDEZ-VIVANCO<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACTURA-SIMON-HERNANDEZ-VIVANCO.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACTURA-TANIA-MARTINEZ-SUAREZ<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACTURA-TANIA-MARTINEZ-SUAREZ.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-035-1-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-035-1-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-043-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-043-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-058-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-058-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-059-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-059-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-052-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-052-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-068-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-068-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-067-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-067-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-069-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-069-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-070-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-070-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-072-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-072-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-071-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-071-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-060-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-060-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-065-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-065-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-062-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-062-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-073-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-073-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-064-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-064-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-063-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-063-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-046-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-046-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-CHAJOR-A-379<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-CHAJOR-A-379.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-BOEKI-A-261<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-BOEKI-A-261.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-BOEKI-A-260<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-BOEKI-A-260.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-HUGO-RODRIGUEZ-1,815.48<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-HUGO-RODRIGUEZ-1,815.48.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-050-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-050-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-HUGO-RODRIGUEZ-4,394.68<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-HUGO-RODRIGUEZ-4,394.68.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-051-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-051-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-HUGO-RODRIGUEZ-18,539.77<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-HUGO-RODRIGUEZ-18,539.77.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-048-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-048-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-CARLOS-NAIHIM-16,038.97<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-CARLOS-NAIHIM-16,038.97.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-038-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-038-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-HUGO-RODRIGUEZ-9,875.56<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-HUGO-RODRIGUEZ-9,875.56.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-056-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-056-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-CARLOS-NAIHIM-15,646.82<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-CARLOS-NAIHIM-15,646.82.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-037-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-037-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-CARLOS-NAIHIM-20,799.96<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-CARLOS-NAIHIM-20,799.96.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-036-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-036-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-PECDA-001-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-PECDA-001-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-073-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-073-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-NOTARIOS-ISLAS&ISLAS<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-NOTARIOS-ISLAS&ISLAS.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-046-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-046-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-048-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-048-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-060-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-060-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-052-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-052-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-051-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-051-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-050-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-050-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-062-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-062-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-063-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-063-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-064-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-064-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-065-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-065-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-067-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-067-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-072-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-072-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-071-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-071-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-070-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-070-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-069-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-069-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-068-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-068-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-073-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-073-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-036-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-036-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-037-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-037-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-038-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-038-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-056-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-056-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-ELVIRA-CHAVEZ<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-ELVIRA-CHAVEZ.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-WAKAWAY<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-WAKAWAY.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">FACT-CAMCOR<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/FACT-CAMCOR.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-060-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-060-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-052-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-052-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-051-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-051-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-050-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-050-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-065-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-065-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-069-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-069-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-063-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-063-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-070-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-070-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-064-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-064-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-067-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-067-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-068-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-068-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-062-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-062-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-072-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-072-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-038-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-038-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-036-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-036-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-056-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-056-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-073-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-073-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-037-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-037-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-CA-CONTRATO-071-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-CA-CONTRATO-071-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2023-octubre">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2023-octubre" aria-expanded="false" aria-controls="collapseOne2023-octubre">2023 | Octubre - Diciembre</button>
              </h2>
              <div id="collapseOne2023-octubre" class="accordion-collapse collapse" aria-labelledby="headingOne2023-octubre" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-group">
                    <li class="list-group-item">SC_DA_CONTRATO_046_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRETO_046_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_048_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_048_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_049_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_049_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_053_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_053_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_054_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_054_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_056_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_056_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_058_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_058_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_059_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRARO_059_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_060_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_060_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_061_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_061_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_062_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_062_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_063_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_063_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_064_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_064_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-026-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-026-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-028-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-028-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-051-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-051-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-057-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-057-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-058-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-058-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-059-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-059-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-060-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-060-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-061-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-061-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-065-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-065-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-066-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-066-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-067-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-067-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-068-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-068-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-069-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-069-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-072-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-072-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-073-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-073-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-074-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-074-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-078-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-078-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-079-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-079-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-080-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-080-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-081-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-081-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-116-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-116-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>

                    <li class="list-group-item">SC-DA-C-PROFEST-01-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-01-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-02-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-02-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-03-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-03-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-05-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-05-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-06-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-06-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-07-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-07-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-08-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-08-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-09-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-09-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-10-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-10-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-11-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-11-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-12-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-12-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-14-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-14-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-15-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-15-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-16-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-16-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-19-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-19-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-20-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-20-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-23-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-23-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-25-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-25-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-29-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-29-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-30-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-30-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-31-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-31-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-32-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-32-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-34-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-34-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-35-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-35-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-36-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-36-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-38-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-38-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-39-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-39-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

        </div>
    </div>
  )
}

export default Contratos